import React from "react";
import styled from "styled-components";
// Assets
import QuotesIcon from "../../assets/svg/Quotes";
import Video from "../../assets/img/empresa.mp4";

export default function Header() {
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">Somos a semente do seu crescimento!</h1>
          <HeaderP className="font13 semiBold">
          A Tectree Publicidade e Marketing é uma agência de marketing digital que oferece soluções criativas para fortalecer marcas online. Atuamos com gestão de redes sociais, SEO, conteúdo e campanhas de performance. Nosso foco é impulsionar negócios com estratégias personalizadas e resultados concretos.
          </HeaderP>
          <BtnWrapper>
            <a href="https://wa.me/5511914005513" >Comece Agora!</a>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Vid src={Video} loop muted autoPlay />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Nós da Intermetro agradecemos à Tectree pelo excelente trabalho e parceria, destacando a criatividade, agilidade e profissionalismo da equipe. Graças às estratégias inovadoras e campanhas eficazes, nossos resultados superaram as expectativas. Estamos extremamente satisfeitos e confiantes no sucesso contínuo dessa colaboração.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px', fontWeight: '700'}}>INTERMETRO</p>
            </div>
          </QuoteWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;

  & h1 {
    font-size: 55px;
  }
  @media (max-width: 580px) {
    h1 {
      font-size: 40px;
      line-height: 2.5rem;
    }
  }
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  background-color: #008101;
  height: 45px;
  border-radius: 15px;

  :hover {
    background-color: #093b1b;
    }

  & a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 45px;
    display: block;
    text-decoration: none;
  }
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Vid = styled.video`
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0 0 0 / 53%);

  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;



