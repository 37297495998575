import React from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

export default function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  function sendEmail(e) {
    e.preventDefault();

    if (!name || !email || !phone || !message) {
      alert("Por favor, preencha todos os campos!");
      return;
    }

    const templateParams = {
      from_name: name,
      message: message,
      email: email,
      phone: phone,
    }

    emailjs.send("service_j49k88a", "template_tcqdkso", templateParams, "-ao7BA4ob0ZZ9LQ1S")
    .then((response) => {
      console.log("Email Enviado!", response.status, response.text);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");

    }, (err) => {
      console.log("Erro ao enviar email!", err);
    });
  }

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Entre em contato</h1>
            <p className="font13">
            Entre em contato conosco pelo formulário abaixo e descubra como podemos ajudar 
            <br />
            a sua empresa a crescer no mundo digital. Nossos especialistas estão prontos para criar 
            <br />
            estratégias personalizadas de marketing digital, tráfego pago e criação de sites. 
            <br />
            Garantimos resultados concretos para alavancar suas vendas e visibilidade online!
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={sendEmail}> 
                <label className="font13">Nome:</label>
                <input 
                  type="text" 
                  id="fname" 
                  name="fname" 
                  className="font20 extraBold" 
                  placeholder="Digite seu nome" 
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <label className="font13">Email:</label>
                <input 
                  type="text" 
                  id="email" 
                  name="email" 
                  className="font20 extraBold"
                  placeholder="Digite seu email" 
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <label className="font13">Telefone:</label>
                <input 
                  type="tel" 
                  id="email" 
                  name="email" 
                  className="font20 extraBold" 
                  placeholder="Digite seu número" 
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
                <label className="font13">Qual sua necessidade?</label>
                <textarea 
                  rows="4" 
                  cols="50" 
                  type="text" 
                  id="message" 
                  name="message" 
                  className="font20 extraBold" 
                  placeholder="Ideias em mente? Mande para gente!" 
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                <SumbitWrapper className="flex SumbitWrapper">
                  <ButtonInput type="submit" value="Enviar" className="pointer animate radius8 ButtonInput" style={{ width: "100%" }} />
                </SumbitWrapper>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn" >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <ContactImgBox>
                    <img src={ContactImg3} alt="office" className="radius6" />
                  </ContactImgBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
    color: #000000;
    :hover {
      color: #008101;
    }
    :-webkit-autofill {
      -webkit-text-fill-color : #000000;
    } 
  }
  .ButtonInput {
    border: 1px solid #008101;
    background-color: #008101;
    width: 100%;
    height: 45px;
    padding: 15px;
    outline: none;
    color: #fff;
    :hover {
      background-color: #093b1b;
      border: 1px solid #093b1b;
      color: #fff;
    }
    @media (max-width: 991px) {
      margin: 0 auto;
    }
  }
  .SumbitWrapper {
    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const ButtonInput = styled.input`
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  
  @media (max-width: 568px) {
    display: none;
  }
`;
const SumbitWrapper = styled.div`
`;









