import * as React from "react";

function IconBxUpArrowCircle(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height={30}
      width={30}
      {...props}
    >
      <path d="M12.001 1.993C6.486 1.994 2 6.48 2 11.994c.001 5.514 4.487 10 10 10 5.515 0 10.001-4.486 10.001-10s-4.486-10-10-10.001zM12 19.994c-4.41 0-7.999-3.589-8-8 0-4.411 3.589-8 8.001-8.001 4.411.001 8 3.59 8 8.001s-3.589 8-8.001 8z" />
      <path d="M12.001 8.001l-4.005 4.005h3.005V16h2v-3.994h3.004z" fill="#ffffff" />
    </svg>
  );
}

export default IconBxUpArrowCircle;