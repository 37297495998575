import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
// Assets
import ProjectImg1 from "../../assets/img/projects/site1.png";
import ProjectImg2 from "../../assets/img/projects/site6.png";
import ProjectImg3 from "../../assets/img/projects/site3.png";
import ProjectImg4 from "../../assets/img/projects/site4.png";
import ProjectImg5 from "../../assets/img/projects/site5.png";
import ProjectImg6 from "../../assets/img/projects/site2.png";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Projects() {
  return (
    <Wrapper id="projetos">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nossos Projetos</h1>
            <p className="font13">
            Já realizamos diversos projetos de sucesso, focados na criação de sites personalizados, incluindo e-commerces, 
            <br />
            landing pages, sites institucionais e catálogos online. Combinando design atrativo e tecnologia de ponta, 
            <br />
            entregamos plataformas funcionais e otimizadas, sempre alinhadas às necessidades de cada cliente.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Site Redegil"
                text="Desenvolvemos o site institucional da Redegil, criando uma plataforma moderna e funcional para fortalecer a presença digital da empresa."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Site Intermetro"
                text="Criamos o site catálogo da Intermetro, oferecendo uma solução digital eficiente para apresentar seus produtos de forma prática e atrativa."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Site Pontual"
                text="Desenvolvemos o e-commerce da Pontual, proporcionando uma experiência de compra online intuitiva e otimizada para seus clientes."
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Site 3 Poderes"
                text="Criamos o e-commerce da 3 Poderes, com um design moderno e uma navegação eficiente para aprimorar a experiência de compra online."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Site Estilo Vertical"
                text="Desenvolvemos a landing page da Estilo Vertical, focada em conversão e design atraente para destacar seus serviços de forma eficaz."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="App"
                text="Desenvolvemos o aplicativo da Conecta Ink, oferecendo uma plataforma mobile inovadora e funcional para conectar usuários e serviços com facilidade."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Feedbacks</h1>
            <p className="font13">
              Nós valorizamos profundamente a satisfação de seus clientes, utilizando seus feedbacks para aperfeiçoar constantemente os serviços oferecidos. 
              <br />
              Cada projeto é desenvolvido com foco em resultados concretos, sempre alinhado às necessidades e expectativas, 
              <br />
              garantindo uma parceria de sucesso e o crescimento digital de cada empresa.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
