import * as React from "react";

function IconSeeding(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      width={80.459} 
      height={50.42}
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M12 10a6 6 0 00-6-6H3v2a6 6 0 006 6h3M12 14a6 6 0 016-6h3v1a6 6 0 01-6 6h-3M12 20V10" 
      fill="#f2b300"/>
    </svg>
  );
}

export default IconSeeding;
