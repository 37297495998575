// BotaoWhats.jsx
import React from 'react';
import WhatsappIcon from '../../assets/svg/Services/Whatsapp';

const BotaoWhats = () => {
  const whatsappLink = "https://wa.me/SEU_NUMERO_DE_TELEFONE";

  const styles = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#008101',
    borderRadius: '50%',
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    zIndex: 1000,

  };

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={styles}>
      <WhatsappIcon />
    </a>
  );
};

export default BotaoWhats;