import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "./TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="A Tectree superou todas as nossas expectativas! O time é extremamente profissional e entregou um trabalho impecável, sempre ouvindo nossas demandas e ajustando tudo com perfeição."
            author="Valéria de Siqueira"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Estamos muito satisfeitos com a Tectree. A equipe criou uma estratégia de marketing digital que trouxe ótimos resultados para o nosso negócio, sempre com muita criatividade e dedicação."
            author="Erika Midori"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Desde o primeiro contato, percebi o comprometimento da Tectree. A criação do nosso site foi impecável, e o suporte contínuo que recebemos faz toda a diferença."
            author="Sr. Oswaldo"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="A parceria com a Tectree foi essencial para o nosso crescimento digital. Profissionais comprometidos, rápidos e com soluções eficazes para cada desafio que apresentamos."
            author="Moisés"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="A equipe da Tectree é incrível! Transformaram nossas ideias em uma campanha de sucesso, sempre atentos aos detalhes e com uma comunicação transparente e eficaz."
            author="Marco Gil"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="O tráfego pago que eles gerenciaram trouxe resultados imediatos, e os relatórios mensais nos ajudam, até hoje, a entender os nossos progressos de campanhas."
            author="Herbert"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
