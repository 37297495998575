import * as React from "react";

function IconTree(props) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      width={80.459} 
      height={50.42}
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M12 13l-2-2M12 12l2-2M12 21V8M9.824 15.995a3 3 0 01-2.743-3.69 2.998 2.998 0 01.304-4.833A3 3 0 0112 3.765a3 3 0 014.614 3.707 2.997 2.997 0 01.305 4.833A3 3 0 0114 16h-4z" fill="#af00f4"/>
    </svg>
  );
}

export default IconTree;

  