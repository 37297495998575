import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// Assets
import AddImage1 from "../../assets/img/add/estrategia.png";
import AddImage2 from "../../assets/img/add/camera.png";
import AddImage3 from "../../assets/img/add/tectree.png";
import AddImage4 from "../../assets/img/add/codigo.png";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Nossos Serviços</h1>
            <p className="font13">
              Amplie sua visibilidade e impulsione suas vendas conosco
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Design"
                subtitle="Oferecemos serviços de design que incluem a criação de artes impactantes, conteúdos estratégicos para redes sociais e desenvolvimento de identidade visual única. Com foco em criatividade e inovação, transformamos ideias em projetos que destacam a essência de cada marca."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Web Design"
                subtitle="Nos destacamos na criação de sites personalizados, incluindo landing pages, sites institucionais, e-commerces e catálogos online. Combinamos design atrativo e tecnologia de ponta para oferecer plataformas funcionais, otimizadas e adaptadas às necessidades de cada cliente, garantindo uma experiência digital de alto impacto."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Tráfego Pago"
                subtitle="A Tectree oferece gestão de tráfego pago em plataformas como Meta (Facebook Business), Google Ads, TikTok Ads e LinkedIn Ads. Com campanhas estratégicas e segmentação precisa, maximizamos a visibilidade e os resultados, impulsionando o crescimento e a conversão de nossos clientes no ambiente digital."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
                icon="printer" 
                title="Relatórios e Análises" 
                subtitle="A Tectree entrega relatórios e análises mensais detalhados, permitindo uma visão clara dos resultados das campanhas. Com base nesses dados, criamos estratégias personalizadas para otimizar o desempenho e garantir que os objetivos dos clientes sejam constantemente superados." 
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Faça seu orçamento!</h4>
                <h2 className="font40 extraBold">Tudo personalizado para você</h2>
                <p className="font12">
                  Oferecemos serviços de marketing digital totalmente personalizados, com estratégias feitas sob medida para cada empresa. Atuamos com criação de conteúdo, gestão de redes sociais, campanhas de tráfego pago e desenvolvimento de sites, sempre focados em resultados concretos e no crescimento digital dos nossos clientes.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <BtnWrapper1>
                    <a href="https://wa.me/5511914005513" >Comece Agora!</a>
                  </BtnWrapper1>
                  <BtnWrapper2>
                    <a href="https://wa.me/5511914005513" >Saiba Mais</a>
                  </BtnWrapper2>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const BtnWrapper1 = styled.div`
  width: 190px;
  background-color: #008101;
  height: 45px;
  border-radius: 15px;

  :hover {
    background-color: #093b1b;
    }

  & a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 45px;
    display: block;
    text-decoration: none;
  }
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const BtnWrapper2 = styled.div`
  width: 190px;
  margin-left: 20px;
  background-color: #0081000;
  height: 45px;
  border-radius: 15px;
  border: 2px solid #008101;

  :hover {
    color: #093b1b;
    border: 2px solid #093b1b;
    }

  & a {
    color: #008101;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 45px;
    display: block;
    text-decoration: none;

    :hover {
      color: #093b1b;
    }
  }
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;