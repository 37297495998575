import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Conheça nossos planos</h1>
            <p className="font13">
            Os <strong>planos mensais da Tectree</strong> são ideais para alavancar as vendas e a visibilidade da sua empresa, 
            <br />
            com estratégias de marketing digital personalizadas, campanhas de tráfego pago e gestão de redes sociais. 
            <br />
            Focados em resultados concretos, nossos planos garantem um crescimento constante e sustentável no ambiente digital.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="seed"
                title="Plano Seed"
                text="Ideal para empresas que querem ingressar no mundo digital, com estratégias básicas e personalizadas para começar a fortalecer sua presença online."
                offers={[
                  { name: "Criação de arte", cheked: true },
                  { name: "Estratégia de tráfego pago", cheked: true },
                  { name: "Otimização de Business Manager", cheked: true },
                  { name: "Gerenciamento das Redes Sociais", cheked: false },
                  { name: "Criação e Manutenção de Site", cheked: false },
                  { name: "Produção e edição de vídeos", cheked: false },
                  { name: "", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="plant"
                title="Plano Tree"
                text="Perfeito para empresas que já estão no mundo digital e buscam aprimorar sua presença online com estratégias avançadas de marketing digital e otimização de resultados."
                offers={[
                  { name: "Criação de arte", cheked: true },
                  { name: "Estratégia de tráfego pago", cheked: true },
                  { name: "Otimização de Business Manager", cheked: true },
                  { name: "Gerenciamento das Redes Sociais", cheked: true },
                  { name: "Criação e Manutenção de Site", cheked: true },
                  { name: "Produção e edição de vídeos", cheked: false },
                  { name: "", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="tree"
                title="Plano Tree +"
                text="Ideal para empresas que já estão no mundo digital e querem alcançar novos patamares, com estratégias robustas de marketing digital e crescimento acelerado."
                offers={[
                  { name: "Criação de arte", cheked: true },
                  { name: "Estratégia de tráfego pago", cheked: true },
                  { name: "Otimização de Business Manager", cheked: true },
                  { name: "Gerenciamento das Redes Sociais", cheked: true },
                  { name: "Criação e Manutenção de Site", cheked: true },
                  { name: "Produção e edição de vídeos", cheked: true },
                  { name: "", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;





